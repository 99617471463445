import React from 'react'
import Container from '@packages/justo-parts/lib/components/Container'
import parse, {domToReact, Element, HTMLReactParserOptions} from 'html-react-parser'
import omit from 'lodash/omit'
import Link from 'next/link'

export default function Custom(props) {
  const {html, css, fullWidth} = props

  const options: HTMLReactParserOptions = {
    replace: domNode => {
      if (domNode instanceof Element === false) return

      const {name, attribs, children} = domNode as Element
      if (name === 'a') {
        // Return Internal Link
        if (attribs.href.startsWith('/')) {
          return (
            <Link href={attribs.href} className={attribs.class}>
              {domToReact(children, options)}
            </Link>
          )
        }
        // Return External Link
        return (
          <a
            {...omit(attribs, 'class')}
            className={attribs.class}
            target="_blank"
            rel="noopener noreferrer">
            {domToReact(children, options)}
          </a>
        )
      }

      if (name === 'body') {
        return <div>{domToReact(children, options)}</div>
      }

      if (name === 'head') {
        return <></>
      }
    }
  }

  const contentHTML = parse(html, options)
  const contentCSS = <style suppressHydrationWarning>{css}</style>

  return (
    <>
      {fullWidth ? contentHTML : <Container>{contentHTML}</Container>}
      {contentCSS}
    </>
  )
}
